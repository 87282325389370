<template>
  <div class="home" id="body">
        <div class="why-section">
        <div class="container">
            <div class="row">
                <div class="col">
					<div class="section-title">
							<h1>Warum ID-Beratung</h1>
							<div class="header-border grey" />
					</div>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-md-4">
                    <p class="text-why">Für jedes gemeinsame Projekt steht Ihnen ihr persönliches Expertenteam zur Verfügung. Wir berücksichtigen individuell Ihre Anforderungen, Ihre Unternehmenskultur und Ihre Wünsche. <br></p>
                </div>
                <div class="col col-12 col-md-4">
                    <p class="text-why">Partnerschaftlich und nachhaltig arbeiten wir mit unseren Mandanten zusammen, um eine erfolgreiche und langjährige Geschäftsbeziehung zu erzielen.<br></p>
                </div>
                <div class="col col-12 col-md-4">
                    <p class="text-why">Unsere Spezialisten verfügen über jahrelange Branchenkenntnisse, ein belastbares Netzwerk, ausgezeichnete Referenzen und Partner. Wir beraten zertifiziert, innovativ und kompetent.<br></p>
                </div>
            </div>
        </div>
    </div><!--
    <div class="about-section">
        <div class="container">
            <div class="about">
                <div class="about-image"><img src="../assets/images/raise.png"></div>
                <div class="about-text">
                    <h1 style="color: rgb(205,206,208);">Lorem ipsum dolor sit amet</h1> auskommentiert
                    <p class="about-text">"The only way to do great<br>work, is to love what<br>you do"</p>
                </div>
            </div>
        </div>
    </div>-->
	<div class="about-part">
		<div class="container">
		<p class="quote"><strong>"The only way to do great work,<br> is to love what you do"</strong> <br> 
		</p>
		</div>
	</div>
	<div class="carousel slide" data-ride="carousel" data-interval="false" id="carousel-1">
        <div class="carousel-inner">
            <div class="carousel-item features-slide">
				<div class="features-boxed">
					<div class="container">
						<div class="section-title">
							<div class="row">
								<div class="col-3">
								</div>
								<div class="col-6">
									<h1>Recruiting</h1>
									<div class="header-border grey" />
								</div>
								<div class="col-3 back-button">
									<a href="#carousel-1" data-target="#carousel-1" data-slide-to="1" ><font-awesome-icon class="icon" icon="chevron-right" /></a>
								</div>
							</div>
						</div>
						<div class="row display-flex justify-content-center">
							<!--<div class="col-sm-8 col-md-6 col-lg-3">
								<div class="navbox-container">
									<div class="navbox">
									<ul>
										<a href="#"><li class="navbox-item active"><span>Warum</span></li></a>
										<a href="#"><li class="navbox-item"><span>Schwerpunkte</span></li></a>
										<a href="#"><li class="navbox-item"><span>Menschen zusammenführen</span></li></a>
										<a href="#carousel-1" data-target="#carousel-1" data-slide-to="1"><li class="navbox-item"><span><font-awesome-icon class="icon" icon="chevron-right" /></span></li></a>
									</ul>
									</div>
								</div>
							</div>-->
							<div class="col-sm-11 col-md-10 col-lg-9 recruiting">
								<h2>Warum ID-Beratung</h2>
								<p>Wir haben es uns zur Aufgabe gemacht, Arbeitgeber und Arbeitnehmer erfolgreich und leistungsstark zusammenzubringen. <br> <br> Personalberatung ist für uns mehr als nur ein Job. Dabei berücksichtigen wir neben den Hard Skills unserer Bewerber auch die Soft Skills und gleichen diese mit der Unternehmenskultur und den Anforderungen ihres Unternehmens ab. So stellen wir sicher, die richtigen Wunschkandidaten für ihr Team zu finden.</p>
								<h2>Wir führen Menschen Zusammen</h2>
								<p><strong>Individuelle Suche.</strong> In jedem Projekt nehmen wir zunächst gemeinsam mit unserem Auftraggeber dessen Anforderungen auf und erarbeiten gemeinsam die Herangehensweise. Um den für Sie größtmöglichen Erfolg zu generieren, nutzen wir je nach Anforderung alle uns zur Verfügung stehenden Recruiting-Kanäle. Durch individuelle Ansprachen finden wir so gefragte Spezialisten für ihr Unternehmen. Nicht zuletzt helfen uns unser exzellentes Netzwerk aus langjährigen Beschäftigungsverhältnissen in Führungspositionen von Großkonzernen und eine Vielzahl von wertvollen Beziehungen zu Groß- und Mittelstandskunden in ganz Deutschland. <br> <br> <strong>Ein kompetenter Ansprechpartner.</strong> Sie haben bei der BusinessProtect24 immer einen festen Ansprechpartner, der Ihr  Projekt koordiniert. Er wird Sie während des gesamten Recruiting-Prozesses über Ihre Bewerber informieren und steht sowohl in ständigem Kontakt mit den Kandidaten als auch mit ihnen.</p>
								<h2>Herangehensweise</h2>
								<p>Ein persönliches Kennenlernen ist uns wichtig. Idealerweise starten wir dazu mit einem gemeinsamen KickOff in ihrem Unternehmen. Ziel ist es, ihre Anforderungen, ihre Unternehmenskultur und ihre Wünsche aufzunehmen und gemeinsam die daraus abgeleitete Herangehensweise zu entwickeln. <br> <br> Die Berücksichtigung und das Verständnis ihrer Unternehmenskultur spielt eine große Rolle für uns. Wir finden die Menschen, die ihr Unternehmen lieben und dafür die Extrameile gehen werden. <br> Natürlich bedienen wir uns der bewährten Kanäle , wie z.B. Active Sourcing, Stellenanzeigen ... </p>
								<div class="icons">
									<img src="../assets/images/facebook.png" alt="Facebook">
									<img src="../assets/images/xing.png" alt="Xing">
									<img src="../assets/images/stackoverflow.png" alt="Stackoverflow">
									<img src="../assets/images/indeed.jpeg" alt="Indeed">
									<img src="../assets/images/truffls.svg" alt="Truffls">
									<img src="../assets/images/linkedin.png" alt="LinkedIn">
								</div>
								<p>... was uns darüber hinaus auszeichnet, sind die ausgezeichneten Verbindungen in eine Vielzahl von Unternehmen – deutschlandweit.</p>
								<div>
									<router-link to="/kontakt"><button type="button" class="btn btn-outline-dark">Kontakt</button></router-link>
								</div>
								<!--<table class="table table-borderless">
									<thead>
									<tr>
										<th><h4>Schwerpunkte</h4></th>
										<th><h4>Herangehensweise</h4></th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td><strong>Informationstechnologie</strong></td>
										<td>Ein persönliches Kennenlernen ist uns wichtig. Idealerweise starten wir dazu mit einem gemeinsamen KickOff in ihrem Unternehmen. Ziel ist es, ihre Anforderungen, ihre Unternehmenskultur und ihre Wünsche aufzunehmen und gemeinsam die daraus abgeleitete Herangehensweise zu entwickeln.</td>
									</tr>
									<tr>
										<td><strong>Telekommunikation</strong></td>
										<td>Die Berücksichtigung und das Verständnis ihrer Unternehmenskultur spielt eine große Rolle für uns. Wir finden die Menschen, die ihr Unternehmen lieben und dafür die Extrameile gehen werden.</td>
									</tr>
									<tr>
										<td><strong>Management &amp; Vertrieb</strong></td>
										<td>Natürlich bedienen wir uns der bewährten Kanäle , wie z.B. Active Sourcing, Stellenanzeigen ...</td>
									</tr>
									<tr>
										<td><strong>Beratung &amp; Consulting</strong></td>
										<td>..was uns darüber hinaus auszeichnet, sind die ausgezeichneten Verbindungen in eine Vielzahl von Unternehmen – deutschlandweit.</td>
									</tr>
									</tbody>
								</table>-->
							</div>
						</div>
					</div>
				</div>
			</div>
            <div class="carousel-item features-slide active">
				<div class="features-boxed">
					<div class="container">
						<div class="section-title">
							<h1>Was wir machen</h1>
							<div class="header-border grey" />
						</div>
						<div class="row display-flex justify-content-center">
							<div class="col-sm-12 col-md-6 col-lg-6 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" href="https://businessprotect24.de">
									<div class="box-header">
										<img src="../assets/images/logo.png" alt="BusinessProtect24 Logo" class="icon">
										<h3 class="name"><strong>Datenschutz</strong></h3>
									</div>
									<p class="description">Mit einem Team aus zertifizierten Datenschutzbeauftragten und Volljuristen unterstützen wir mit unserem Partnerunternehmen, der Businessprotect24, mittelständische Unternehmen kompetent und praxisorientiert bei der Umsetzung der Datenschutzgrundverordnung (DSGVO).</p>
									<a
										href="https://businessprotect24.de" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-12 col-md-6 col-lg-6 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" href="#carousel-1" data-target="#carousel-1" data-slide-to="4">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="phone-volume" />
										<h3 class="name"><strong>Telekommunikation</strong></h3>
									</div>
									<p class="description">Kaum eine Branche befindet sich in einem so tiefgreifenden Wandel wie die Telekommunikation. Mit über 20 Jahren Erfahrung in der Branche beraten wir unsere Kunden in nahezu allen Aspekten der Telekommunikation.</p>
									<a href="#carousel-1" data-target="#carousel-1" data-slide-to="4" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<!--<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" href="#carousel-1" data-target="#carousel-1" data-slide-to="2">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="server" />
										<h3 class="name"><strong>Digitalisierung</strong></h3>
									</div>
									<p class="description">Gemeinsam mit unseren Experten entwickeln wir mit Ihnen neue Geschäftsmodelle und digitale Prozesse,&nbsp;die auf Ihren Bedarf abgestimmt sind. Von der&nbsp;Konzeptionierung und Wirtschaftlichkeitsbetrachtung&nbsp;bis zur technischen
										und organisatorischen Umsetzung<br>führen wir mit Ihnen das gesamte Projekt durch.&nbsp;</p><a href="#carousel-1" data-target="#carousel-1" data-slide-to="2" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>-->
							<!--<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" href="https://energieberatung-mittelstand.online/">
									<div class="box-header">
										<img src="../assets/images/ENBiM.png" alt="Energieberatung Mittelstand Logo" class="icon">
										<h3 class="name"><strong>Energieberatung</strong></h3>
									</div>
									<p class="description">Eine sparsame Energieverwendung in Unternehmen leistet einen wesentlichen Beitrag zur Energiesicherheit in Deutschland und zum globalen Klimaschutz. Mit der eigens dafür geschaffenen Marke, der Energieberatung-Mittelstand, zeigen Ihnen unsere von der BAFA (Bundesamt für Wirtschaft und Ausfuhrkontrolle) zertifizierten Ingenieure mögliche Einsparpotentiale auf.</p><a href="https://energieberatung-mittelstand.online/" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>-->
							<!--<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" href="#carousel-1" data-target="#carousel-1" data-slide-to="0">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="object-group" />
										<h3 class="name"><strong>Recruiting</strong></h3>
									</div>
									<p class="description">In Zeiten in denen in Deutschland in rund 400 Berufen qualifiziertes Fachpersonal fehlt, haben wir es uns zur Aufgabe gemacht,&nbsp;<br>Arbeitgeber und Arbeitnehmer erfolgreich<br>und leistungsstark zusammenzubringen.<br></p><a
										href="#carousel-1" data-target="#carousel-1" data-slide-to="0" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>-->
							<!--<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" href="#carousel-1" data-target="#carousel-1" data-slide-to="3">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="bookmark" />
										<h3 class="name"><strong>Schulung</strong></h3>
									</div>
									<p class="description">Ihre eigenen Produktlösungen und die interne IT entwickeln sich unaufhörlich weiter. Deshalb ist es wichtig, IT- und Produkt-Schulungen effizient zu managen. Trotz hervorragender Blended Learning- und E-Learning-Lösungen ist Präsenztraining nach wie vor an vielen Stellen nicht ersetzbar.</p><a href="#carousel-1" data-target="#carousel-1" data-slide-to="3" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>-->
							<!--<div class="col-12 item">
								<div class="box" data-aos="zoom-in" data-aos-delay="100">
									<div class="box-header">
										<img src="../assets/images/location.svg" alt="Standortakquisition für Mobilfunk und Netzausbau" class="icon">
										<h3 class="name"><strong>Standortakquisition für Mobilfunk und Netzausbau</strong></h3>
									</div>
									<p class="description">Als Experten für die Gewinnung und Sicherung von geeigneten Standorten sowohl für den Mobilfunkausbau als auch für den Ausbau der Ladeinfrastruktur im Rahmen der wachsenden Elektromobilität für Deutschland kennen wir die Anforderungen der Netzbetreiber sehr genau. Professionell akquirieren wir die geeignetsten Flächen und führen erfolgreich die Verhandlungen bis zum Vertragsabschluss mit den Gemeinden, Behörden und Eigentümern.</p>
								</div>
							</div>-->
						</div>
					</div>
				</div>
			</div>
			<div class="carousel-item features-slide">
				<div class="features-boxed">
					<div class="container">
						<div class="section-title">
							<div class="row">
								<div class="col-3 back-button">
									<a href="#carousel-1" data-target="#carousel-1" data-slide-to="1" ><font-awesome-icon class="icon" icon="chevron-left" /></a>
								</div>
								<div class="col-6">
									<h1>Digitalisierung</h1>
									<div class="header-border grey" />
								</div>
								<div class="col-3">
								</div>
							</div>

						</div>
						<div class="row display-flex justify-content-center">
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" data-toggle="collapse" href="#des-1" @click="changeToContactBox('des-1-b')" role="button" aria-expanded="false" aria-controls="des-1">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="compass" />
										<h3 class="name"><strong>Digitalisierungs- Strategie entwickeln</strong></h3>
									</div>
									<p class="description">Wo steht Ihr Unternehmen heute und was sind die Prioritäten für die nächsten Jahre?</p>
									<div class="collapse" id="des-1">
										<div class="box-header-border grey" />
										<p class="description">Welchen Einfluss hat Digitalisierung auf Ihr Unternehmen und Ihre Kunden? Wir arbeiten mit Ihnen heraus, wie Sie von Umsatzsteigerung, Qualit ̈atsverbesserungoder Kostensenkung durch Digitalisierung profitieren k ̈onnen und berechnen, inwieweit der Nutzen den Aufwand ̈ubersteigt.</p>
									</div>
									<a
										data-toggle="collapse" href="#des-1" @click="changeToContact('des-1-b')" id="des-1-b" role="button" aria-expanded="false" aria-controls="des-1" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" data-toggle="collapse" href="#des-2" @click="changeToContactBox('des-2-b')" role="button" aria-expanded="false" aria-controls="des-2">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="business-time" />
										<h3 class="name"><strong>Nachhaltige Geschäftsmodelle definieren</strong></h3>
									</div>
									<p class="description">Märkte und Kundenanforderungen verändern sich durch die Digitalisierung.</p>
									<div class="collapse" id="des-2">
										<div class="box-header-border grey" />
										<p class="description">Viele unserer Kunden müssen sich neu orientieren, wie sie zukünftig ihre Umsätze erzielen. Wir nutzen moderne Methoden wie Design Thinking und Business Model Canvas, um sie dabei zu unterstützen.</p>
									</div>
									<a data-toggle="collapse" href="#des-2" @click="changeToContact('des-2-b')" id="des-2-b" role="button" aria-expanded="false" aria-controls="des-2" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" data-toggle="collapse" href="#des-3" @click="changeToContactBox('des-3-b')" role="button" aria-expanded="false" aria-controls="des-3">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="project-diagram" />
										<h3 class="name"><strong>Digitale Prozesse in Produktion und Verwaltung</strong></h3>
									</div>
									<p class="description">Wie viel Effizienter können Sie arbeiten?<br>Welche Qualitätsteigerungen erwarten?</p>
									<div class="collapse" id="des-3">
										<div class="box-header-border grey" />
										<p class="description">Wissen Sie, wie viel Effizienzsteigerung und Qualitätsverbesserungen Sie durch den Einsatz digitaler Technologien wie Internet der Dinge, Künstliche Intelligenz und moderner Software erzielen können? Mit unserer an Lean Six Sigma angelehnten Methodik finden wir das schnell heraus und definieren mit Ihnen digital gestützte Prozesse.</p>
									</div>
									<a data-toggle="collapse" href="#des-3" @click="changeToContact('des-3-b')" id="des-3-b" role="button" aria-expanded="false" aria-controls="des-3" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" data-toggle="collapse" href="#des-4" @click="changeToContactBox('des-4-b')" role="button" aria-expanded="false" aria-controls="des-4">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="laptop-code" />
										<h3 class="name"><strong>Auswahl der Technologie</strong></h3>
									</div>
									<p class="description">Die Vielfalt digitaler Technologien ist groß, und ständig kommen neue hinzu.</p>
									<div class="collapse" id="des-4">
										<div class="box-header-border grey" />
										<p class="description">Deshalb haben wir Experten für die verschiedenen Bereiche wie Industrie 4.0, Digitaler Arbeitsplatz, Internet der Dinge, Künstliche Intelligenz und Blockchain, die immer den Überblick über die Technologien und Anbieter in ihrem Bereich haben und verstehen, welche Technik und welche Unternehmen wie ausgereift sind und für welche Anwendungsfälle sie sich eignen.</p>
									</div>
									<a data-toggle="collapse" href="#des-4" @click="changeToContact('des-4-b')" id="des-4-b" role="button" aria-expanded="false" aria-controls="des-4" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" data-toggle="collapse" href="#des-5" @click="changeToContactBox('des-5-b')" role="button" aria-expanded="false" aria-controls="des-5">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="handshake" />
										<h3 class="name"><strong>Ganzheitliche Projektsteuerung</strong></h3>
									</div>
									<p class="description">Digitalisierungsprojekte sind oft technisch komplex.</p>
									<div class="collapse" id="des-5">
										<div class="box-header-border grey" />
										<p class="description">Gleichzeitig sind die prozessualen und kulturellen Veränderungen im Unternehmen groß – Digitalisierung ist nie nur ein IT-Projekt. Unsere erfahrenen Projektmanager sind es gewohnt, mehrere Partner durch komplexe Projekte zu steuern und mit allen Ebenen im Unternehmen zu arbeiten. So liefern wir für Sie Gesamtprojekte mit greifbarem Wert.</p>
									</div>
									<a
										data-toggle="collapse" href="#des-5" @click="changeToContact('des-5-b')" id="des-5-b" role="button" aria-expanded="false" aria-controls="des-5" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" data-toggle="collapse" href="#des-6" @click="changeToContactBox('des-6-b')" role="button" aria-expanded="false" aria-controls="des-6">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="mail-bulk" />
										<h3 class="name"><strong>Systemintegration und Schnittstellen</strong></h3>
									</div>
									<p class="description">Digitalisierungsprojekte bestehen oft aus mehreren Komponenten</p>
									<div class="collapse" id="des-6">
										<div class="box-header-border grey" />
										<p class="description">Digitalisierungsprojekte bestehen oft aus mehreren technischen Komponenten, und echte Verbesserungen können nur erzielt werden, wenn diese Komponenten reibungslos zusammenarbeiten. Deshalb entwickeln wir die nötigen Schnittstellen und sorgen dafür, dass Prozesse von Anfang bis Ende digital abgebildet werden.</p>
									</div>
									<a data-toggle="collapse" href="#des-6" @click="changeToContact('des-6-b')" id="des-6-b" role="button" aria-expanded="false" aria-controls="des-6" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" data-toggle="collapse" href="#des-7" @click="changeToContactBox('des-7-b')" role="button" aria-expanded="false" aria-controls="des-7">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="adjust" />
										<h3 class="name"><strong>Change Management</strong></h3>
									</div>
									<p class="description">Mitarbeiter und Führungskräfte empfinden Digitalisierung oft als Herausforderung.</p>
									<div class="collapse" id="des-7">
										<div class="box-header-border grey" />
										<p class="description">Das gewohnte Arbeitsumfeld und bekannte Abläufe verändern sich stark. Nur durch offene und gezielte Kommunikation und Einbindung der Mitarbeiter von Anfang an gelingt es, Mitarbeiter von notwendigen Verhaltensänderungen zu überzeugen, so dass sich neue Prozesse nachhaltig im Unternehmen etablieren. Wir unterstützen bei der Planung und Durchführung des Kulturwandels.</p>
									</div>
									<a data-toggle="collapse" href="#des-7" @click="changeToContact('des-7-b')" id="des-7-b" role="button" aria-expanded="false" aria-controls="des-7" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" data-toggle="collapse" href="#des-8" @click="changeToContactBox('des-8-b')" role="button" aria-expanded="false" aria-controls="des-8">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="network-wired" />
										<h3 class="name"><strong>Betrieb technischer Lösungen</strong></h3>
									</div>
									<p class="description">Gerne betreiben wir die Gesamtlösung für Sie.</p>
									<div class="collapse" id="des-8">
										<div class="box-header-border grey" />
										<p class="description">Vor allem im Bereich Internet der Dinge und Datenanalyse / Künstliche Intelligenz ist eine stabile, skalierbare und sichere Architektur und Infrastruktur extrem wichtig. Unsere Data Scientists und KI-Entwickler generieren neue Erkenntnisse aus Ihren Daten.</p>
									</div>
									<a
										data-toggle="collapse" href="#des-8" @click="changeToContact('des-8-b')" id="des-8-b" role="button" aria-expanded="false" aria-controls="des-8" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" data-toggle="collapse" href="#des-9" @click="changeToContactBox('des-9-b')" role="button" aria-expanded="false" aria-controls="des-9">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="industry" />
										<h3 class="name"><strong>Internet der Dinge - LoRaWAN / 5G</strong></h3>
									</div>
									<p class="description">Bauen Sie ein eigenes IOT-Netzwerk mit unserer Hilfe.</p>
									<div class="collapse" id="des-9">
										<div class="box-header-border grey" />
										<p class="description">Für Unternehmen mit großen Flächen oder Stadtwerke / Kommunen kann es interessant sein, ein eigenes Netz für die Maschinen zu bauen und zu betreiben, zum Teil als Geschäftsmodell, zum Teil aus Sicherheitsgründen. Für mehrere Kunden bauen wir großflächige LoRaWAN Netzwerke für Eigennutzung und Vermietung. Im Rahmen von 5G wird es auch möglich sein, eigene lokale 5G Netzwerke zu betreiben.</p>
									</div>
									<a data-toggle="collapse" href="#des-9" @click="changeToContact('des-9-b')" id="des-9-b" role="button" aria-expanded="false" aria-controls="des-9" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="carousel-item features-slide">
				<div class="features-boxed">
					<div class="container">
						<div class="section-title">
							<div class="row">
								<div class="col-3 back-button">
									<a href="#carousel-1" data-target="#carousel-1" data-slide-to="1" ><font-awesome-icon class="icon" icon="chevron-left" /></a>
								</div>
								<div class="col-6">
									<h1>Schulung</h1>
									<div class="header-border grey" />
								</div>
								<div class="col-3">
								</div>
							</div>
						</div>
						<div class="row display-flex justify-content-center">
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" data-toggle="collapse" href="#des-tra-1" @click="changeToContactBox('des-tra-1-b')" role="button" aria-expanded="false" aria-controls="des-tra-1">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="laptop-code" />
										<h3 class="name"><strong>IT-Training</strong></h3>
									</div>
									<p class="description">Ob produktspezifisch oder ganz auf den Kunden zugeschnitten – wir bieten unseren Firmenkunden und Behörden die IT-Weiterbildung, die Ihre Mitarbeiter wirklich benötigen.</p>
									<div class="collapse" id="des-tra-1">
										<div class="box-header-border grey" />
										<p class="description">Wir wissen, dass gerade im IT-Bereich die breit angelegten Kompetenz-Ansprüche der betrieblichen Organisation und die „gefühlten“ Weiterbildungsbedarfe der einzelnen Mitarbeiter auseinanderdriften können. Deshalb bieten wir Organisationen nicht nur eine Ausbildungsleistung „von der Stange“, sondern unterstützen Sie von der Vorab-Beratung zu individualisierten Einzellehrgängen oder Lehrgangsserien bis hin zur Übernahme des „Full Service“ Ihres IT-Bildungsoutsourcings. Sprechen Sie mit uns über die Möglichkeiten einer passgenauen IT-Weiterbildung, die Ihre Mitarbeiter mit den für sie und ihre Arbeitssituation passenden Lern- und Ausbildungsmöglichkeiten „versorgt“ – als Präsenzseminar, Blended-Learning-Konzept oder „Training-on-The-Job“-Maßnahme.</p>
									</div>
									<a
										data-toggle="collapse" href="#des-tra-1" @click="changeToContact('des-tra-1-b')" id="des-tra-1-b" role="button" aria-expanded="false" aria-controls="des-tra-1" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" data-toggle="collapse" href="#des-tra-2" @click="changeToContactBox('des-tra-2-b')" role="button" aria-expanded="false" aria-controls="des-tra-2">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="boxes" />
										<h3 class="name"><strong>Produkttraining</strong></h3>
									</div>
									<p class="description">Wir sorgen dafür, dass Ihre Produkte gut ankommen.</p>
									<div class="collapse" id="des-tra-2">
										<div class="box-header-border grey" />
										<p class="description">Wir übernehmen für Sie das Management und die Umsetzung aller trainings- und supportrelevanten Methoden und Prozesse. Schnell und flexibel. In Deutschland oder wo immer Sie möchten. Mit Trainern, die über langjährige Erfahrung in der Vermittlung komplexer technischer und betriebswirtschaftlicher Sachverhalte verfügen. Gut für Ihre Kunden, Händler, Mitarbeiter. Und für Ihr Produkt.</p>
									</div>
									<a data-toggle="collapse" href="#des-tra-2" @click="changeToContact('des-tra-2-b')" id="des-tra-2-b" role="button" aria-expanded="false" aria-controls="des-tra-2" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" data-toggle="collapse" href="#des-tra-3" @click="changeToContactBox('des-tra-3-b')" role="button" aria-expanded="false" aria-controls="des-tra-3">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="project-diagram" />
										<h3 class="name"><strong>Managed Training Services</strong></h3>
									</div>
									<p class="description">Für die Organisiation umfassender Schulungsprojekte ist straffes Projektmanagement gefragt.</p>
									<div class="collapse" id="des-tra-3">
										<div class="box-header-border grey" />
										<p class="description">Beispielsweise, um bei SAP Anwendern synchron zum Software Rollout zu trainieren. Oder um Akademien im Outsourcing zu betreiben. Wir entlasten Ihre Personalentwicklung phasenweise oder dauerhaft.</p>
									</div>
									<a data-toggle="collapse" href="#des-tra-3" @click="changeToContact('des-tra-3-b')" id="des-tra-3-b" role="button" aria-expanded="false" aria-controls="des-tra-3" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" data-toggle="collapse" href="#des-tra-4" @click="changeToContactBox('des-tra-4-b')" role="button" aria-expanded="false" aria-controls="des-tra-4">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="box" />
										<h3 class="name"><strong>Classroom in a box</strong></h3>
									</div>
									<p class="description">Warum sollte man heute noch permanente EDV-Schulungsräume betreiben,<br> wenn man die Ausstattung "just in time" anfordern kann?</p>
									<div class="collapse" id="des-tra-4">
										<div class="box-header-border grey" />
										<p class="description">Das digitale Merkmal "Sharing" trifft auch für EDV-Schulungsräume zu. Alles, was Sie brauchen, ist ein Besprechungsraum. Alles andere mieten Sie sich bei uns. Der "Classroom in a Box" beeinhaltet 13 Teilnehmer-PC, 1 Trainer-PC, 1 Server, Ersatzgeräte, Beamer, Drucker ... einfach einen kompletten EDV Schulungsraum. Dieser wird in drei Alukisten geliefert und ist in etwa 30 Minuten einsatzbereit. Derzeit ist für rund 600 Teilnehmer Hardware im Einsatz.</p>
									</div>
									<a data-toggle="collapse" href="#des-tra-4" @click="changeToContact('des-tra-4-b')" id="des-tra-4-b" role="button" aria-expanded="false" aria-controls="des-tra-4" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" data-toggle="collapse" href="#des-tra-5" @click="changeToContactBox('des-tra-5-b')" role="button" aria-expanded="false" aria-controls="des-tra-5">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="handshake" />
										<h3 class="name"><strong>Vertriebstraining</strong></h3>
									</div>
									<p class="description">Verkäufer müssen heute eine Top-Performance abliefern, um Kunden zu gewinnen.</p>
									<div class="collapse" id="des-tra-5">
										<div class="box-header-border grey" />
										<p class="description">Oft haben sie nur wenige Minuten Zeit, um Produkt und Unternehmen im besten Licht erscheinen zu lassen und überzeugend zu argumentieren. In jedem Unternehmen, egal ob mittelständischer Handwerksbetrieb oder multinationaler Konzern, kommt dem Vertrieb im Zusammenspiel mit dem Marketing bzgl. der Kundengewinnung und Umsatzgenerierung eine Schlüsselrolle zu. Schließlich sind es die Sales Manager, Key Account Manager, Außendienst-Mitarbeiter und Innendienst-Mitarbeiter, die für den Verkauf der hergestellten Produkte oder angebotenen Dienstleistungen verantwortlich sind – und damit für den wirtschaftlichen Erfolg des Unternehmens. Wir bilden Ihre Verkäufer zu den Top Performern aus, die jederzeit  mit Ihren Kunden auf Augenhöhe verhandeln können und Waren oder Dienstleistungen im B2B- oder B2C-Bereich erfolgreich verkaufen.</p>
									</div>
									<a
										data-toggle="collapse" href="#des-tra-5" @click="changeToContact('des-tra-5-b')" id="des-tra-5-b" role="button" aria-expanded="false" aria-controls="des-tra-5" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" data-toggle="collapse" href="#des-tra-6" @click="changeToContactBox('des-tra-6-b')" role="button" aria-expanded="false" aria-controls="des-tra-6">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="shield-alt" />
										<h3 class="name"><strong>DSGVO-Training</strong></h3>
									</div>
									<p class="description">Wir qualifizieren Ihren internen Datenschutzbeauftragten und stellen seine fachliche Qualifikation gemäß Artikel 37 DSGVO sicher.</p>
									<div class="collapse" id="des-tra-6">
										<div class="box-header-border grey" />
										<p class="description">Darüber hinaus schulen wir Ihre Mitarbeiter individuell auf das Thema Datenschutz und vermitteln Ihnen so, was dies für Ihre tägliche Arbeit im Umgang mit personenbezogenen Daten bedeutet.</p>
									</div>
									<a data-toggle="collapse" href="#des-tra-6" @click="changeToContact('des-tra-6-b')" id="des-tra-6-b" role="button" aria-expanded="false" aria-controls="des-tra-6" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="carousel-item features-slide">
				<div class="features-boxed">
					<div class="container">
						<div class="section-title">
							<div class="row">
								<div class="col-3 back-button">
									<a href="#carousel-1" data-target="#carousel-1" data-slide-to="1" ><font-awesome-icon class="icon" icon="chevron-left" /></a>
								</div>
								<div class="col-6">
									<h1>Telekommunikation</h1>
									<div class="header-border grey" />
								</div>
								<div class="col-3">
								</div>
							</div>
						</div>
						<div class="row display-flex justify-content-center">
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" href="https://businessconsult24.de" target="_blank">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="phone-volume" />
										<h3 class="name"><strong>Standortakquisition für Mobilfunk und Netzausbau</strong></h3>
									</div>
									<p class="description">In Zusammenarbeit mit unserem Partnerunternehmen Businessconsult24 agieren wir als Bindeglied zwischen Netzbetreibern und Standorteigentümern. Erfolgreich akquirieren und verhandeln wir geeignete Flächen für den Mobilfunkausbau für alle deutschen Netzbetreiber.</p>
									<a href="https://businessconsult24.de" target="_blank" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" data-toggle="collapse" href="#des-tel-1" @click="changeToContactBox('des-tel-1-b')" role="button" aria-expanded="false" aria-controls="des-tel-1">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="wifi" />
										<h3 class="name"><strong>Mobilfunk</strong></h3>
									</div>
									<p class="description">Als Partner mit über 30 Jahren Branchenerfahrung der marktführenden Carrier kennen wir den Markt genau.</p>
									<div class="collapse" id="des-tel-1">
										<div class="box-header-border grey" />
										<p class="description">Sichern Sie sich mit ID-Beratung die beste Netzabdeckung zum besten Preis. Unser Team unterstützt Sie in allen Bereichen Ihrer unternehmerischen Mobilfunknutzung. Vom Flottenaufbau über die Vertragsverwaltung bis zum Rückkauf von Geräten inklusive ISO zertifizierter Löschung Ihrer Daten.</p>
									</div>
									<a
										data-toggle="collapse" href="#des-tel-1" @click="changeToContact('des-tel-1-b')" id="des-tel-1-b" role="button" aria-expanded="false" aria-controls="des-tel-1" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<!--<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" data-toggle="collapse" href="#des-tel-2" @click="changeToContactBox('des-tel-2-b')" role="button" aria-expanded="false" aria-controls="des-tel-2">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="phone-volume" />
										<h3 class="name"><strong>Festnetz</strong></h3>
									</div>
									<p class="description">Märkte und Kundenanforderungen verändern sich durch die Digitalisierung.</p>
									<div class="collapse" id="des-tel-2">
										<div class="box-header-border grey" />
										<p class="description">Viele unserer Kunden müssen sich neu orientieren, wie sie zukünftig ihre Umsätze erzielen. Wir nutzen moderne Methoden wie Design Thinking und Business Model Canvas, um sie dabei zu unterstützen.</p>
									</div>
									<a data-toggle="collapse" href="#des-tel-2" @click="changeToContact('des-tel-2-b')" id="des-tel-2-b" role="button" aria-expanded="false" aria-controls="des-tel-2" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>-->
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" data-toggle="collapse" href="#des-tel-3" @click="changeToContactBox('des-tel-3-b')" role="button" aria-expanded="false" aria-controls="des-tel-3">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="project-diagram" />
										<h3 class="name"><strong>Festnetz &#38; Internet</strong></h3>
									</div>
									<p class="description">Wie viel Effizienter können Sie arbeiten?<br>Welche Qualitätsteigerungen können Sie erwarten?</p>
									<div class="collapse" id="des-tel-3">
										<div class="box-header-border grey" />
										<p class="description">Wissen Sie, wie viel Effizienzsteigerung und Qualitätsverbesserungen Sie durch den Einsatz digitaler Technologien wie Internet der Dinge, Künstliche Intelligenz und moderner Software erzielen können? Mit unserer an Lean Six Sigma angelehnten Methodik finden wir das schnell heraus und definieren mit Ihnen digital gestützte Prozesse.</p>
									</div>
									<a data-toggle="collapse" href="#des-tel-3" @click="changeToContact('des-tel-3-b')" id="des-tel-3-b" role="button" aria-expanded="false" aria-controls="des-tel-3" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="100" data-toggle="collapse" href="#des-tel-4" @click="changeToContactBox('des-tel-4-b')" role="button" aria-expanded="false" aria-controls="des-tel-4">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="laptop-code" />
										<h3 class="name"><strong>Cloud &amp; Hosting</strong></h3>
									</div>
									<p class="description">Die Vielfalt digitaler Technologien ist groß und es kommen <br>ständig neue hinzu.</p>
									<div class="collapse" id="des-tel-4">
										<div class="box-header-border grey" />
										<p class="description">Unsere Experten für die verschiedenen Bereiche wie Industrie 4.0, Digitaler Arbeitsplatz, Internet der Dinge, Künstliche Intelligenz und Blockchain, behalten immer den Überblick über die Technologien und Anbieter in ihrem Bereich und verstehen, welche Technik und welche Unternehmen wie ausgereift sind und für welche Anwendungsfälle sie sich eignen.</p>
									</div>
									<a data-toggle="collapse" href="#des-tel-4" @click="changeToContact('des-tel-4-b')" id="des-tel-4-b" role="button" aria-expanded="false" aria-controls="des-tel-4" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="200" data-toggle="collapse" href="#des-tel-5" @click="changeToContactBox('des-tel-5-b')" role="button" aria-expanded="false" aria-controls="des-tel-5">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="handshake" />
										<h3 class="name"><strong>Standortvernetzung</strong></h3>
									</div>
									<p class="description">Möglichkeiten der Standortvernetzung: von Ethernet bis MPLS.</p>
									<div class="collapse" id="des-tel-5">
										<div class="box-header-border grey" />
										<p class="description">Die Wahl einer Standortvernetzung zur Anbindung von Unternehmensniederlassungen ans Firmennetz, oder
											auch mobiler Mitarbeiter im Home Office, stellt jeden Netzwerkadministrator vor eine Herausforderung. Die
											Entscheidung zwischen Internet-VPN, Ethernet-Netzwerk oder MPLS-VPN ist nicht leicht. Unsere Consulter
											zeigen Ihnen, welche Faktoren bei der Wahl der Netztechnologie Sie berücksichtigen sollten, um ein optimales
											Ergebnis für das eigene Unternehmen sicherzustellen.
										</p>
									</div>
									<a
										data-toggle="collapse" href="#des-tel-5" @click="changeToContact('des-tel-5-b')" id="des-tel-5-b" role="button" aria-expanded="false" aria-controls="des-tel-5" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
							<div class="col-sm-6 col-md-5 col-lg-4 item">
								<a class="box" data-aos="zoom-in" data-aos-delay="300" data-toggle="collapse" href="#des-tel-6" @click="changeToContactBox('des-tel-6-b')" role="button" aria-expanded="false" aria-controls="des-tel-6">
									<div class="box-header">
										<font-awesome-icon class="icon" icon="mail-bulk" />
										<h3 class="name"><strong>Netzwerksicherheit</strong></h3>
									</div>
									<p class="description">Digitalisierungsprojekte bestehen oft aus mehreren Komponenten.</p>
									<div class="collapse" id="des-tel-6">
										<div class="box-header-border grey" />
										<p class="description">Digitalisierungsprojekte bestehen oft aus mehreren technischen Komponenten, und echte Verbesserungen können nur erzielt werden, wenn diese Komponenten reibungslos zusammenarbeiten. Deshalb entwickeln wir die nötigen Schnittstellen und sorgen dafür, dass Prozesse von Anfang bis Ende digital abgebildet werden.</p>
									</div>
									<a data-toggle="collapse" href="#des-tel-6" @click="changeToContact('des-tel-6-b')" id="des-tel-6-b" role="button" aria-expanded="false" aria-controls="des-tel-6" class="learn-more">Mehr erfahren »</a>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "home",
  components: {
    
  },
  methods: {
	changeToContact(id) {
		if (document.getElementById(id).innerHTML != "Mehr erfahren »")
		{
			this.$router.push("/kontakt");
		}
	},
	changeToContactBox(id) {
		if (document.getElementById(id).innerHTML == "Mehr erfahren »")
		{
			document.getElementById(id).innerHTML = "Kontakt »";
			document.getElementById(id).href = "./kontakt";
		} else {
			document.getElementById(id).innerHTML = "Mehr erfahren »";
			document.getElementById(id).href = "#" + id.replace("-b", "");
		}
	}
  }
};
</script>
